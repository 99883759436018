/**
 * Copyright ©2024 Drivepoint
 */

import React from "react";
import {Button} from "@mui/material";
import {closeSnackbar, enqueueSnackbar, SnackbarKey} from "notistack";

type ToastErrorInfo = {
  message: string;
  reload?: boolean;
};

type ToastErrorAdditionalOptions = {
  persist?: boolean
};

export default class Toast {

  static error(error: Error, options: ToastErrorAdditionalOptions = {}): void {
    if (!error) { return; }
    const errorInfo = Toast._getErrorInfo(error);
    const action = (snackbarId: SnackbarKey | undefined) => (
      <>
        {errorInfo.reload && <Button color="inherit" onClick={() => { window.location.reload(); }}>Refresh</Button>}
        <Button color="inherit" onClick={() => { closeSnackbar(snackbarId); }}>Dismiss</Button>
      </>
    );
    enqueueSnackbar(
      errorInfo.message, {
        action, variant: "error",
        anchorOrigin: {horizontal: "center", vertical: "top"},
        persist: options.persist ?? false
      });
  }

  static success(message: string): void {
    if (!message) { return; }
    const action = (snackbarId: SnackbarKey | undefined) => (
      <Button color="inherit" onClick={() => { closeSnackbar(snackbarId); }}>Dismiss</Button>
    );
    enqueueSnackbar(message, {action, variant: "success", anchorOrigin: {horizontal: "center", vertical: "top"}});
  }

  static progress(message: string): SnackbarKey | undefined {
    if (!message) { return; }
    return enqueueSnackbar(message, {persist: true, anchorOrigin: {horizontal: "center", vertical: "top"}});
  }

  private static _getErrorInfo(error: any): ToastErrorInfo {
    const info: ToastErrorInfo = {message: error.message};
    if (error instanceof TypeError) {
      if (error.message === "Failed to fetch") {
        info.message = "There was a problem connecting to our service, please wait and try again.";
      } else {
        info.message = "An unexpected error occurred, please refresh the page and try again.";
      }
      info.reload = true;
    }
    switch (error.code) {
      case 409:
        info.message = "This item already exists. Please choose a new one";
        break;
      case 404:
        info.message = "The item was not found.";
        break;
      case 423:
        info.message = "The item is currently locked, please try again.";
        break;
    }
    return info;
  }

}
